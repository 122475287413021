/* CustomScrollbar.css */

.custom-scrollbar {
    /* Defina uma altura m�xima para a div para que a barra de rolagem apare�a */
    max-height: 400px; /* Ajuste conforme necess�rio */
    overflow-y: scroll; /* Adiciona a barra de rolagem vertical */
    scrollbar-width: thin; /* Largura da barra de rolagem */
    scrollbar-color: #888888 #ffffff; /* Cor da barra e cor do fundo */
}

    .custom-scrollbar::-webkit-scrollbar {
        width: 8px; /* Largura da barra de rolagem para navegadores baseados em Webkit */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background-color: #888888; /* Cor da al�a da barra de rolagem */
    }

        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
            background-color: #555555; /* Cor da al�a da barra de rolagem ao passar o mouse */
        }
