.scrollbar-300 {
    /*margin-left: 30px;*/
    float: left;
    height: 300px;
    width: 300px;
    background: #fff;
    overflow-y: scroll;
    margin-bottom: 25px;
}

.scrollbar-500 {
    /*margin-left: 30px;*/
    float: left;
    height: 500px;
    /*width: 500px;*/
    background: #fff;
    overflow-y: scroll;
    margin-bottom: 25px;
}

.force-overflow {
    min-height: 450px;
}

.scrollbar-primary::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #4285F4;
}

.scrollbar-primary {
    scrollbar-color: #4285F4 #F5F5F5;
}

.scrollbar-danger::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbar-danger::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-danger::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #ff3547;
}

.scrollbar-danger {
    scrollbar-color: #ff3547 #F5F5F5;
}

.scrollbar-warning::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbar-warning::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-warning::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #FF8800;
}

.scrollbar-warning {
    scrollbar-color: #FF8800 #F5F5F5;
}

.scrollbar-success::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbar-success::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-success::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #00C851;
}

.scrollbar-success {
    scrollbar-color: #00C851 #F5F5F5;
}

.scrollbar-info::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbar-info::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-info::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #33b5e5;
}

.scrollbar-info {
    scrollbar-color: #33b5e5 #F5F5F5;
}

.scrollbar-default::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbar-default::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-default::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #2BBBAD;
}

.scrollbar-default {
    scrollbar-color: #2BBBAD #F5F5F5;
}

.scrollbar-secondary::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbar-secondary::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-secondary::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #aa66cc;
}

.scrollbar-secondary {
    scrollbar-color: #aa66cc #F5F5F5;
}


.scrollbar-pink::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbar-pink::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-pink::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #ec407a;
}

.scrollbar-pink {
    scrollbar-color: #ec407a #F5F5F5;
}

.scrollbar-indigo::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbar-indigo::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-indigo::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #3f51b5;
}

.scrollbar-indigo {
    scrollbar-color: #3f51b5 #F5F5F5;
}

.scrollbar-black::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbar-black::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-black::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #000;
}

.scrollbar-black {
    scrollbar-color: #000 #F5F5F5;
}

.scrollbar-lady-lips::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbar-lady-lips::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-lady-lips::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(linear, left bottom, left top, from(#ff9a9e), color-stop(99%, #fecfef), to(#fecfef));
    background-image: -webkit-linear-gradient(bottom, #ff9a9e 0%, #fecfef 99%, #fecfef 100%);
    background-image: linear-gradient(to top, #ff9a9e 0%, #fecfef 99%, #fecfef 100%);
}

.scrollbar-near-moon::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbar-near-moon::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-near-moon::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(linear, left bottom, left top, from(#5ee7df), to(#b490ca));
    background-image: -webkit-linear-gradient(bottom, #5ee7df 0%, #b490ca 100%);
    background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%);
}

.bordered-pink::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border: 1px solid #ec407a;
}

.bordered-pink::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none;
}

.bordered-indigo::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border: 1px solid #3f51b5;
}

.bordered-indigo::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none;
}

.bordered-black::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border: 1px solid #000;
}

.bordered-black::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none;
}

.square::-webkit-scrollbar-track {
    border-radius: 0 !important;
}

.square::-webkit-scrollbar-thumb {
    border-radius: 0 !important;
}

.thin::-webkit-scrollbar {
    width: 6px;
}
