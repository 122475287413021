/* Estilos globais */
body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.pagination .page-link {
    background: black;
    color: white;
    border: 1px solid black;
    transition: background 0.3s, color 0.3s;
}

    .pagination .page-link:hover,
    .pagination .page-link:focus,
    .pagination .page-link:active {
        background: white;
        color: black;
        border: 1px solid black;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        outline: none;
    }

    .pagination .page-item.active .page-link {
        background: white;
        color: black;
        border: 1px solid black;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        outline: none;
    }

#wrapper {
    transition: all 0.3s ease;
}

.toggled #sidebar {
    margin-left: -250px;
}

#page-content-wrapper {
    width: 100%;
    padding-left: 15px;
}

/* Estilos para o Sidebar */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    background-color: #333;
    color: #fff;
}

    .sidebar ul {
        list-style-type: none;
        padding: 0;
    }

        .sidebar ul li {
            padding: 8px;
        }

            .sidebar ul li a {
                color: #fff;
                text-decoration: none;
            }

                .sidebar ul li a:hover {
                    text-decoration: underline;
                }

/* Estilos para o Navbar */
.navbar {
    background-color: #222;
}

.navbar-brand {
    color: #fff;
    font-weight: bold;
}

    .navbar-brand:hover {
        color: #fff;
    }

/* Estilos para o conte�do das p�ginas */
.container-fluid {
    margin-top: 20px;
}

/* Estilos para corrigir a posi��o do Sidebar */
@media (max-width: 767.98px) {
    .toggled {
        margin-left: -250px;
    }
}

.nav-pills .nav-link {
    background-color: #343a40; /* Cor de fundo escura */
    color: #ffffff; /* Texto em branco para contraste */
}

    .nav-pills .nav-link.active {
        background-color: #1e2124; /* Cor de fundo mais escura para o item ativo */
        color: #ffffff; /* Texto em branco para o item ativo */
    }

