body {
}
/* src/sidebar.css */
#sidebar {
    min-width: 250px;
    max-width: 250px;
}

#page-content-wrapper {
    min-width: calc(100vw - 250px);
}

/* Add animation to the sidebar */
#sidebar {
    transition: all 0.3s;
}

#page-content-wrapper {
    transition: all 0.3s;
}

/* Adjust the page content when the sidebar is toggled */
#wrapper.toggled #sidebar {
    margin-left: -250px;
}

#wrapper.toggled #page-content-wrapper {
    margin-right: 250px;
}

/* Button to toggle the sidebar */
#menu-toggle {
    display: block;
    margin-left: auto;
    margin-right: 15px;
}
